import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from "react";
import {apiWithBills} from "services/client/billing/bills";
import {useCallback} from "react";
import Link from "Link";

interface IApproveBill {
  show: boolean
  proceed(data: any): void
  record: {
    [key: string]: any
  }
}

const ApproveBill: React.FCC<IApproveBill> = ({show, proceed, record}) => {
  const [approveBill, {isLoading}] = apiWithBills.useApproveBillMutation();

  const handleApproveClick = useCallback(() => {
    approveBill({id: record.id}).unwrap()
      .then((response) => {
        proceed(response)
      })
  }, [])

  return (
    <Dialog
      open={show}
      onClose={() => proceed(false)}
    >
      <DialogTitle>Approve bill</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Before approving the bill, please look through the session list.
          If the price or the status of the session isn`t properly arranged, please dispute it.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => proceed(false)}>
          Cancel
        </Button>
        <Button href={`/client/billing/bills/${record.id}/sessions`} component={Link}>Sessions</Button>
        <Button
          autoFocus
          variant="contained"
          onClick={handleApproveClick}
          disabled={isLoading}
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveBill;
// export default confirmable(ApproveBill);