import React, {useCallback} from "react";
import {useFormInModal} from "components/clients/form/useFormInModal";
import TopUpPayment from "./TopUpPayment";
import TopUpStatus from "./TopUpStatus";
import {useRouter} from "next/router";

const TopUp = () => {
  const router = useRouter()

  const [TopUpInDialodWindow] = useFormInModal(TopUpPayment, {name: 'topup-payment'});
  const [TopUpStatusDialodWindow, {handleClose: handleClosePaymentStatus}] = useFormInModal(TopUpStatus, {name: 'topup-status'});

  const onBackPaymentStatus = useCallback(() => router.push('/client/billing/dashboard#modal=topup-payment'), [])
  const onClosePaymentStatus = useCallback(() => {
    router.push('/client/billing/dashboard')
    handleClosePaymentStatus()
  }, [])


  // @ts-ignore
  return <>
    {/*@ts-ignore*/}
    <TopUpStatusDialodWindow onBack={onBackPaymentStatus} onClose={onClosePaymentStatus} />

    {/*@ts-ignore*/}
    <TopUpInDialodWindow onClose={handleClosePaymentStatus} />
  </>;
}

export default TopUp