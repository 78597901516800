import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ListItemIcon, ListItemText, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {BoxLabel, BoxRow} from "./Boxes";
import PaymentMethodImage from "./PaymentMethodImage";
import Stack from "@mui/material/Stack";
import {FCC} from "react";

interface IPaymentMethods {
  paymentMethod: {[key: string]: any}
  paymentMethods: {[key: string]: any}[],
  onChange(value: any): void
}

const PaymentMethods: FCC<IPaymentMethods> = ({paymentMethod, paymentMethods = [], onChange = (value) => {}}) => {
  return <BoxRow>
    <BoxLabel>
      <Typography variant='subtitle2'>Payment method</Typography>
    </BoxLabel>
    <Box>
      <Select
        value={paymentMethod}
        onChange={(e) => onChange(e.target.value)}
        sx={{minWidth: '250px'}}
      >
        {paymentMethods.map((paymentMethod) => {
          //  @ts-ignore
          return <MenuItem value={paymentMethod} key={paymentMethod.id}>
              <Stack
                direction='row'
                alignItems="center"
                sx={{
                  maxWidth: '10rem',
                }}
              >
                <ListItemIcon>
                  <PaymentMethodImage name={paymentMethod.options?.brand} variant='outline' />
                </ListItemIcon>
                <ListItemText>
                  {paymentMethod.name}
                </ListItemText>
              </Stack>
            </MenuItem>
          }
        )}
      </Select>
    </Box>
  </BoxRow>
}

export default PaymentMethods