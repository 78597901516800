import { clientSplitApi } from '../clientSplitApi'
import {ApiModelResponse} from "../../common/types";

export const apiWithTopup = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    makeOfflinePayment: build.mutation<ApiModelResponse, Record<string, any>>({
      query(data) {
        return {
          url: `/billing/offline-checkout`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Balance', 'Transaction', 'Payment']
    }),
  }),
  overrideExisting: false,
});


