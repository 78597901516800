import {apiWithBills} from "services/client/billing/bills";
import {apiWithInvoices} from "services/client/billing/invoices";
import {apiWithTransactions} from "services/client/billing/transactions";
import {apiWithBalances} from "services/client/billing/balances";
import {apiWithPaymentMethod} from "services/client/billing/payment-methods";

const queryOptions = {
  pollingInterval: 300 * 1000,
}

export const useBillingDashboardData = () => {
  const pagination = {
    size: 5,
    page: 1,
  };

  const balancesQuery = apiWithBalances.useGetBalancesForTopUpQuery(undefined, queryOptions);

  // @ts-ignore
  const paymentMethodsQuery = apiWithPaymentMethod.useGetTopPaymentMethodsQuery();

  const billsQuery = apiWithBills.useGetBillsQuery({
    pagination: {...pagination},
    sorting: [
      {
        name: 'created_at',
        direction: 'desc',
      }
    ],
  }, queryOptions);

  const invoicesQuery = apiWithInvoices.useGetInvoicesQuery({
    pagination: {...pagination},
    sorting: [
      {
        name: 'date',
        direction: 'desc',
      }
    ],
  }, queryOptions);

  const transactionsQuery = apiWithTransactions.useGetTransactionsQuery({
    pagination: {...pagination},
    sorting: [
      {
        name: 'date',
        direction: 'desc',
      }
    ],
  }, queryOptions);

  return {billsQuery, invoicesQuery, transactionsQuery, balancesQuery, paymentMethodsQuery}
}