import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";
import {FCC, useCallback, useState} from "react";
import {apiWithBalances} from "services/client/billing/balances";
import {apiWithPaymentMethod} from "services/client/billing/payment-methods";
import {default as axios} from "axios";
import {apiWithTopup} from "services/client/billing/topup";
import TopUpPaymentForm from "./TopUpPaymentForm";
import PaymentCheckoutResponse from "./PaymentCheckoutResponse";
import {Balance, PaymentMethod} from "../../../../services/client/billing/types";

const withData = <P,>(WrappedComponent: FCC<P>): FCC<P> => {
  return (props) => {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState('payment');

    // @ts-ignore
    const {data: balances} = apiWithBalances.useGetBalancesForTopUpQuery();
    // @ts-ignore
    const {data: paymentMethods} = apiWithPaymentMethod.useGetTopPaymentMethodsQuery();
    // @ts-ignore
    const [makeOfflinePayment, {data: offlinePaymentData}] = apiWithTopup.useMakeOfflinePaymentMutation();

    const handleTopUpSubmit = useCallback((props) => {
      setLoading(true)

      if (props.paymentMethod.offline) {
        handleOfflineTopUpSubmit(props)
      } else {
        handleOnlineTopUpSubmit(props)
      }
    }, [loading]);

    const handleOnlineTopUpSubmit = useCallback(({amount, paymentMethod, balance, amountType}) => {
      axios
        .post(paymentMethod.checkout_url, {
          amount: amount,
          currency_code: balance.currency.code,
        }, {
          withCredentials: true
        })
        .then(response => {
          window.location.href = response.data.url;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setLoading(false)
        });
    }, [])

    const handleOfflineTopUpSubmit = useCallback(({amount, paymentMethod, balance, amountType}) => {
      makeOfflinePayment({
          amount: amount,
          currency_code: balance.currency.code,
          payment_method: paymentMethod,
        })
        .unwrap()
        .then(response => {
          console.log('response', response)

          setStep('status')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setLoading(false)
        });
    }, [])

    if (!balances || !paymentMethods) {
      return <CircularProgress />
    }

    return <WrappedComponent
      loading={loading}
      step={step}
      balances={balances.data}
      paymentMethods={paymentMethods.data}
      onSubmit={handleTopUpSubmit}
      offlinePaymentData={offlinePaymentData}
      {...props}
    />
  }
}

interface ITopUpPayment {
  loading?: boolean
  step: string
  offlinePaymentData: {[key: string]: any}
  onBack?():void
  onClose?(): void
  onSubmit(data: any): void
  balances: Balance[]
  paymentMethods: PaymentMethod[]
}

const TopUpPayment: FCC<ITopUpPayment>
  = ({loading, step, offlinePaymentData, onBack, onClose, onSubmit, balances, paymentMethods}) => {

  return <Box>
    <Box mb={2}>
      <Typography variant='h6'>Top up balance</Typography>
    </Box>

    {step === 'payment' && <TopUpPaymentForm
      loading={loading}
      balances={balances}
      paymentMethods={paymentMethods}
      onClose={onClose}
      onSubmit={onSubmit}
    />}

    {step === 'status' && <PaymentCheckoutResponse
      offlinePaymentData={offlinePaymentData}
      onClose={onClose}
    />}

  </Box>
}

// export default TopUpPayment
export default withData(TopUpPayment)