import Box from "@mui/material/Box";
import React from "react";
import { styled } from '@mui/system';
import Stack from "@mui/material/Stack";
import InputUnstyled from '@mui/base/Input';
import Typography from "@mui/material/Typography";
import {Balance} from "../../../../services/client/billing/types";
import Image from "next/legacy/image";

interface IMyFormControlLabel {
  value: string,
  checked: boolean,
  onChange(data: any): void,
  children: any,
  customInput?: boolean,
  error?: string,
  hint?: string
}

const MyFormControlLabel = ({value, checked, onChange, children, customInput = false, error, hint}: IMyFormControlLabel) => {
  return <Box>
    <Box sx={{position: 'relative', display: 'flex', alignItems: 'flex-start'}}>
      <Box sx={{width: '40px', cursor: 'pointer', display: 'flex', alignContent: 'center', padding: '10px'}} onClick={() => onChange(value)} >
        <Image alt='' width={20} height={20} src={`/client/assets/images/components/radio-${checked ? 'checked' : 'unchecked'}.png`} />
      </Box>
      <Box sx={{width: '250px'}}>
        <Box onClick={() => onChange(value)}>
          {React.cloneElement(children, {
            onClick: () => onChange(value),
            sx: {
              opacity: checked ? 1 : 0.5,
              backgroundColor: (checked && !customInput) ? 'primary.main' : 'inherit',
              color: checked ? 'primary.contrastText' : 'inherit',
            },
          })}
        </Box>
        {!!hint && <Typography variant='caption' component='div' sx={{marginTop: '0.3rem'}}>{hint}</Typography>}
      </Box>
    </Box>
    {!!error && <Typography color='red' variant='caption' sx={{marginLeft: '40px'}}>{error}</Typography>}

  </Box>
}

const CustomRadio = styled(Box)(({ theme }) => ({
  color: theme.palette.primary,
  // backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1),
  border: '1px solid rgba(49, 52, 53, 0.43)',
  borderRadius: theme.shape.borderRadius,
  width: '250px',
  cursor: 'pointer',
}))

const StyledInputElement = styled('input')(
  ({ theme }) => `
     padding: 8px;
    border: 1px solid rgba(49, 52, 53, 0.43);
    border-radius: 4px;
    width: 250px;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin: 0;
  
    &:focus {
      outline: 3px solid ${theme.palette.primary.main};
    }
  `,
);

const CustomInput = React.forwardRef(function CustomInput(props: typeof InputUnstyled | {onChange(e: any): void}, ref) {
  return (
    // @ts-ignore
    <InputUnstyled components={{ Input: StyledInputElement }} {...props} ref={ref} />
  );
});

interface IAmountSelector {
  balance: Balance
  type: string
  onChange(type: string, value?: number | undefined): void
  error: string
}

const AmountSelector = ({balance, type, onChange, error}: IAmountSelector) => {
  return <Box
    sx={{
      marginLeft: '-40px'
    }}
  >
    <Stack spacing={1}>
      {balance.options.statement_amount !== 0 && <MyFormControlLabel value={'statement'} checked={type === 'statement'} onChange={() => {
        onChange('statement', balance.options.statement_amount)
      }}>
        <CustomRadio>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography>Statement amount</Typography>
            <Typography sx={{width: '50px'}}>{balance.options.statement_amount}</Typography>
          </Stack>
        </CustomRadio>
      </MyFormControlLabel>}
      <MyFormControlLabel
        value={'recommended'}
        checked={type === 'recommended'}
        onChange={() => {onChange('recommended', balance.options.recommended_amount)}}
        hint={'Recommended sum is a double sum of your bill. In case you`re not rest assured you will be able to make timely payment next month, you may choose this option to prevent content blocking.'}
      >
        <CustomRadio>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography>Recommended</Typography>
            <Typography sx={{width: '50px'}}>{balance.options.recommended_amount}</Typography>
          </Stack>
        </CustomRadio>
      </MyFormControlLabel>
      <MyFormControlLabel
        value={'custom'}
        checked={type === 'custom'}
        onChange={() => {
          if (type !== 'custom') {
            onChange('custom', undefined)
          }
        }}
        customInput
        error={error}
      >
        <Box>
          {type !== 'custom' && <CustomRadio><Typography>Other amount</Typography></CustomRadio>}

          {type === 'custom' && <CustomInput
            /* @ts-ignore */
            onChange={(e: any) => {
              onChange('custom', parseFloat(e.target.value));
            }}
          />}
        </Box>
      </MyFormControlLabel>
    </Stack>
  </Box>
}

export default AmountSelector