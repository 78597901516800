import Box from "@mui/material/Box";

import {apiWithPaymentMethod} from "services/client/billing/payment-methods";
import {LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import PaymentMethodImage from "components/clients/billing/balances/PaymentMethodImage";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import {apiWithBalances} from "services/client/billing/balances";
import {useMemo} from "react";
import {Balance, DefaultPaymentMethod} from "../../../../services/client/billing/types";

const Col1 = styled(Box)(() => ({
  width: '100px'
}))


const Col2 = styled(Box)(() => ({
  width: '240px'
}))

const BalancePaymentMethods = () => {
  // @ts-ignore
  const {data: defaultPaymentMethods, isFetching: isLoadingDefaultPaymentMethods} = apiWithPaymentMethod.useGetDefaultPaymentMethodsQuery();

  // @ts-ignore
  const {data: balances, isFetching: isLoadingBalances} = apiWithBalances.useGetBalancesQuery();

  const balancesWithoutPaymentMethods = useMemo(() => {
    if (!balances || !defaultPaymentMethods) {
      return []
    }

    return balances?.data?.filter((balance) => {
      return !defaultPaymentMethods?.data?.map((method) => method.currency.code).includes(balance.currency.code)
    })
  }, [defaultPaymentMethods, balances])

  if (isLoadingDefaultPaymentMethods || isLoadingBalances) {
    return <LinearProgress />
  }

  return <Box>
    <Stack>
      <Stack
        direction='row'
        alignItems='center'
      >
        <Col1>
          <Typography variant='subtitle2'>Currency</Typography>
        </Col1>
        <Col2>
          <Typography variant='subtitle2'>Default payment method</Typography>
        </Col2>
      </Stack>


      {
        defaultPaymentMethods?.data?.map((method) => {
          return <Stack
            direction='row'
            alignItems='center'
            key={method.id}
          >
            <Col1
              sx={{fontSize: '1rem', padding: '4px'}}
            >
              {method.currency.code}
            </Col1>
            <Col2>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                  background: '#F5F1F1',
                  borderRadius: '8px',
                  maxWidth: '240px',
                  padding: '0.5rem 1rem',
                }}
              >
                <PaymentMethodImage name={method.paymentMethod?.paymentable?.brand} variant='outline' />
                <Typography>
                  {method.paymentMethod?.paymentable?.human_name}
                </Typography>
              </Stack>
            </Col2>
          </Stack>
        })
      }

      {
        balancesWithoutPaymentMethods?.map((balance) => {
          return <Stack
            direction='row'
            alignItems='center'
            key={balance.currency.id}
          >
            <Col1
              sx={{fontSize: '1rem', padding: '4px'}}
            >
              {balance.currency.code}
            </Col1>
            <Col2>
              <Typography variant='body2'>Need to setup</Typography>
            </Col2>
          </Stack>
        })
      }
    </Stack>
  </Box>
}

export default BalancePaymentMethods