import React, {FCC, ReactElement, ReactNode} from "react";
import Box from "@mui/material/Box";
import withEditable from "./withEditable";

export interface IVrpRenderFunctionFieldProps {
  source?: string
  label?: string
  newLine?: boolean
  sortable?: boolean
  width?: number
  record?: {[key: string]: any}
  children(data: Omit<IVrpRenderFunctionFieldProps, 'children'>): ReactElement<any, any> | null,
  headerComponent?: FCC
}

const VrpRenderFunctionField = ({children, ...props}: IVrpRenderFunctionFieldProps) => {
  return <Box>{React.createElement(children, props)}</Box>
}

export default withEditable<IVrpRenderFunctionFieldProps>(VrpRenderFunctionField)

