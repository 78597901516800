import { clientSplitApi } from '../clientSplitApi'
import {ApiModelResponse, ApiModelsResponse} from "../../common/types";

export const apiWithPaymentMethod = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getPaymentMethods: build.query<ApiModelsResponse, Record<string, any>>({
      query(args) {
        return {
          url: `/payment-methods`,
          params: {
            context: JSON.stringify(args)
          }
        }
      },
      providesTags: ['PaymentMethod']
    }),
    getTopPaymentMethods: build.query<ApiModelsResponse, void>({
      query() {
        return {
          url: `/payment-methods/topup`,
        }
      },
      providesTags: ['PaymentMethod']
    }),
    getDefaultPaymentMethods: build.query<ApiModelsResponse, void>({
      query() {
        return {
          url: `/balance-payment-methods/defaults`,
        }
      },
      providesTags: ['PaymentMethod']
    }),
    getCurrencyPaymentMethods: build.query<ApiModelsResponse, Record<string, any>>({
      query({currency}) {
        return {
          url: `/balance-payment-methods`,
          params: {
            context: JSON.stringify({
              filtering: {
                currency: currency
              }
            })
          }
        }
      },
      providesTags: ['PaymentMethod']
    }),
    deleteBalancePaymentMethod: build.mutation<ApiModelResponse, Record<string, any>>({
      query({id}) {
        return {
          url: `/balance-payment-methods/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['PaymentMethod']
    }),
    deletePaymentMethod: build.mutation<ApiModelResponse, Record<string, any>>({
      query({id}) {
        return {
          url: `/payment-methods/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['PaymentMethod']
    }),
    addBalancePaymentMethod: build.mutation<ApiModelResponse, Record<string, any>>({
      query(data) {
        return {
          url: `/balance-payment-methods`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['PaymentMethod']
    }),
    saveBalancePaymentMethodsOrder: build.mutation<ApiModelResponse, Record<string, any>>({
      query(data) {
        return {
          url: `/balance-payment-methods/order`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['PaymentMethod']
    }),
  }),
  overrideExisting: false,
});


