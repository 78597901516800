import { clientSplitApi } from '../clientSplitApi'
import {ApiModelResponse, ApiModelsResponse} from "../../common/types";

export const BillStatuses = {
  INVALID: 1,
  IN_REVIEW: 2,
  TO_APPROVE: 3,
  APPROVED: 4,
  DISPUTED: 6,
  CANCELLED: 7,
  DELETED: 8,
}

export const apiWithBills = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getBills: build.query<ApiModelsResponse, Record<string, any>>({
      query(args) {
        return {
          url: `/bills`,
          params: {
            context: JSON.stringify(args)
          }
        }
      },
      providesTags: ['Bill']
    }),
    getBill: build.query<ApiModelResponse, Record<string, any>>({
      query(args) {
        return {
          url: `/bills/${args.id}`,
        }
      },
      providesTags: ['Bill']
    }),
    approveBill: build.mutation<ApiModelResponse, Record<string, any>>({
      query(args) {
        return {
          url: `/bills/${args.id}/approve`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Bill', 'Invoice', 'Balance']
    }),
  }),
  overrideExisting: false,
});


