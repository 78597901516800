import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {CircularProgress} from "@mui/material";
import {apiWithPayments} from "services/client/billing/payments";
import {FCC, useEffect} from "react";
import {useLocationHashParameters} from "components/shared/helpers/url";
import DangerousIcon from '@mui/icons-material/Dangerous';

interface IWithDataProps {
  onBack?(): void
  onClose?(): void
}

const withData = <P,>(WrappedComponent: FCC<P>): FCC<IWithDataProps> => {
  return (props) => {
    const {onBack = () => {}, onClose = () => {}} = props

    const hashParameters = useLocationHashParameters()

    const [checkPaymentStatus, {isLoading, data}] = apiWithPayments.useCheckPaymentStatusMutation()

    useEffect(() => {
      checkPaymentStatus(hashParameters)
    }, [hashParameters.session])

    // @ts-ignore
    return <WrappedComponent
      isLoading={isLoading}
      data={data}
      onClose={onClose}
      onBack={onBack}
    />
  }
}

interface ITopUpStatus {
  isLoading: boolean
  data: any
}

const TopUpStatus: FCC<ITopUpStatus & IWithDataProps> = ({isLoading, data, onClose, onBack}) => {
  return <Box>
    <Box mb={2}>
      <Typography variant='h6'>Payment status</Typography>
    </Box>
    <Box mt={4} mb={4}>
      {isLoading && <Stack direction="column" alignItems="center">
        <CircularProgress/>
        <Typography variant="body2" mt={2}>
          Please wait. Completing the transaction.
        </Typography>
      </Stack>}
      {!isLoading && data?.success && <Typography>
        {data?.success}
      </Typography>}
      {!isLoading && data?.error && <Stack direction="column" alignItems="center">
        <DangerousIcon  color='error' fontSize="large" />
        <Typography color='error' mt={1}>{data?.error}</Typography>
      </Stack>}
    </Box>
    <Stack direction="row" justifyContent="flex-end" spacing={3}>
      <Button onClick={onClose} variant="outlined">Close</Button>
      <Button onClick={onBack} variant='contained'>Charge another balance</Button>
    </Stack>
  </Box>
}

export default withData(TopUpStatus)