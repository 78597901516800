import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "Link";
import {styled} from "@mui/material/styles";
import {StyledComponent} from "@emotion/styled";

const CardButton: typeof Button | StyledComponent<any> = styled(Button)(() => ({
  color: '#fff',
  borderColor: '#fff !important',
  borderWidth: '1px !important',
  minWidth: '80px',
  padding: '0px 9px'
}))

interface IBalanceCard {
  currencyCode: string
  amount: number
  disablePayNow?: boolean
  sx?: Record<string, any>
}

const BalanceCard = (props: IBalanceCard) => {
  return <Box
    sx={{
      backgroundColor: '#006FBA',
      borderRadius: '0.75rem',
      fontWeight: 'bold',
      position: 'relative',
      minWidth: '14rem',
      // height: '100px',
      ...props.sx
    }}
  >
    <Stack
      sx={{
        backgroundImage: 'url(/client/assets/images/components/cards/bg-with-logo.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        padding: '1.1rem 1.1rem',
      }}
    >
      <Box>
        <Typography color='#fff' variant='subtitle2'>{props.currencyCode} balance</Typography>
      </Box>
      <Box mt={2} mb={2} ml={1}>
        <Typography color='#fff' variant='h5'>{props.amount}</Typography>
      </Box>
      <Stack
        direction='row'
        spacing={2}
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography
          component={Link}
          variant='caption'
          href={`/client/billing/history?currency=${props.currencyCode}`}
          color='#fff'
        >
          Transactions
        </Typography>

        {!props.disablePayNow && <CardButton
          component={Link}
          size="small"
          variant='outlined'
          href={`/client/billing/dashboard#modal=topup-payment&currency=${props.currencyCode}`}
        >
          Pay now
        </CardButton>}
      </Stack>
    </Stack>

  </Box>
}

BalanceCard.propTypes = {
  currencyCode: PropTypes.string,
  amount: PropTypes.number,
  size: PropTypes.string,
  disablePayNow: PropTypes.bool
}

BalanceCard.defaultProps = {
  disablePayNow: false
}

export default BalanceCard