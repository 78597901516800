import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import VrpTable from "components/shared/vrp-table/VrpTable";
import VrpTextField from "components/shared/vrp-table/fields/VrpTextField";
import React from "react";
import {useBillingDashboardData} from "components/clients/billing/useBillingDashboardData";
import VrpLinkField from "components/shared/vrp-table/fields/VrpLinkField";
import {Grid, LinearProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Link from "Link";
import BalanceCard from "./BalanceCard";
import BalancePaymentMethods from "./BalancePaymentMethods";
import VrpRenderFunctionField, {
  IVrpRenderFunctionFieldProps
} from "components/shared/vrp-table/fields/VrpRenderFunctionField";
import {BillStatuses} from "services/client/billing/bills";
import {useCurrentUser} from "../../../shared/helpers/auth";
import useFormAction from "../../../shared/actions/useFormAction";
import ApproveBill from "../bills/ApproveBill";
import {Balance} from "../../../../services/client/billing/types";
import CardTitle from "../../ui/card/CardTitle";
import Card from "../../ui/card/Card";
import {getLeftRowsInPagination} from "../../../shared/vrp-table/utils/utils";

const Dashboard = () => {
  const {can} = useCurrentUser();
  const {billsQuery, invoicesQuery, transactionsQuery, balancesQuery} = useBillingDashboardData()
  const ApproveBillFormAction = useFormAction(ApproveBill);

  const billsLeft = getLeftRowsInPagination(billsQuery?.data?.meta)
  const invoicesLeft = getLeftRowsInPagination(invoicesQuery?.data?.meta)
  const transactionsLeft = getLeftRowsInPagination(transactionsQuery?.data?.meta)

  return <Box>
    <Card mb={2}>
      <CardTitle mb={3} onClick={balancesQuery.refetch}>Balance overview</CardTitle>

      <Box>
        <Stack direction="row" flexWrap='wrap' sx={{gap: '2rem'}}>
          {
            balancesQuery.data?.data?.map((balance) => {
              return <BalanceCard
                key={balance.currency?.code}
                currencyCode={balance.currency?.code}
                amount={balance.amount}
                disablePayNow={!balance.__can?.topup}
              />
            })
          }
        </Stack>
      </Box>

      {!!balancesQuery?.isFetching && <LinearProgress/>}
    </Card>

    <Grid
      container
      spacing={2}
    >
      <Grid item xs={6}>
        <Card sx={{height: '100%'}}>
          <CardTitle mb={1} onClick={billsQuery.refetch}>Bills history</CardTitle>

          <VrpTable
            records={billsQuery.data?.data}
            loading={billsQuery?.isFetching}
          >
            <VrpTextField source="created_at" label="Date"/>
            <VrpTextField source="arvi_vr_revenue" label="ARVI VR Revenue"/>
            <VrpRenderFunctionField source='status.human_name' label="Status">
              {
                (props: IVrpRenderFunctionFieldProps) => {
                  if (props.record?.status?.id === BillStatuses.APPROVED) {
                    return <Typography variant='body2'>{props.record?.status?.human_name}</Typography>
                  } else if (props.record?.status?.id === BillStatuses.TO_APPROVE) {
                    return <Box pt={1}>
                      {/*@ts-ignore*/}
                      {!!props.record?.__can?.approve && <ApproveBillFormAction record={props.record}>
                        <Button variant='contained' size='small'>Approve</Button>
                      </ApproveBillFormAction>}
                      <Typography variant='caption' component='div' sx={{color: 'info.main'}}>Automatic approve
                        on {props.record?.auto_approve_at}</Typography>
                    </Box>
                  } else {
                    return <Typography variant='body2'>{props.record?.status?.human_name}</Typography>
                  }
                }
              }
            </VrpRenderFunctionField>
          </VrpTable>

          <Box
            sx={{
              textAlign: 'right',
            }}
          >
            <Typography fontSize={12} lineHeight={'100%'}>{billsLeft ? `and ${billsLeft} more bills. ` : ''}<Link
              href={`/client/billing/bills`}>Show all</Link></Typography>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card sx={{height: '100%'}}>
          <CardTitle mb={1} onClick={transactionsQuery.refetch}>Transactions history</CardTitle>

          <VrpTable
            records={transactionsQuery.data?.data}
            loading={transactionsQuery?.isFetching}
          >
            <VrpTextField source="date" label="Date"/>
            <VrpTextField source="amount" label="Amount"/>
            <VrpTextField source="reason" label="Description"/>
          </VrpTable>

          <Box sx={{textAlign: 'right'}}>
            <Typography fontSize={12}
                        lineHeight={'100%'}>{transactionsLeft ? `and ${transactionsLeft} more transactions. ` : ''}<Link
              href={`/client/billing/history`}>Show all</Link></Typography>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card sx={{height: '100%'}}>
          <Stack
            justifyContent='space-between'
          >
            <Box>
              <CardTitle mb={1}>Payment methods</CardTitle>

              <Box mt={3}>
                <BalancePaymentMethods/>
              </Box>
            </Box>

            <Box sx={{textAlign: 'right'}}>
              {can(['viewAny:paymentMethod']) && <Typography fontSize={12} lineHeight={'100%'}><Link
                href={`/client/company/payment-settings/auto-payments`}>Show all</Link></Typography>}
            </Box>
          </Stack>
        </Card>

      </Grid>

    </Grid>
  </Box>
}

export default Dashboard