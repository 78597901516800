import React, {ComponentType, ReactNode, useCallback, useState} from "react";

export interface IFormAction {
  show?: boolean
  cancel?(): void
  proceed?(data: any): void
}

interface IFormActionProps {
  onProceed?(data: any): void
  onCancel?(): void
  children: ReactNode
}

const useFormAction  = <T,>(Component: ComponentType<T>): React.FCC<T & IFormActionProps> => {
  return ({onProceed = (data: any) => {}, onCancel = () => {}, children, ...props}) => {
    const [show, setShow] = useState(false)

    const proceed = useCallback((response: any) => {
      setShow(false)
      onProceed(response)
    }, [])

    const cancel = useCallback(() => {
      setShow(false)
      onCancel()
    }, [])


    return <>
      {/*@ts-ignore*/}
      {React.cloneElement(children, {
        onClick: () => {
          setShow(true)
        }
      })}

      {/* @ts-ignore*/}
      <Component show={show} cancel={cancel} proceed={proceed} {...props} />
    </>
  }
}

export default useFormAction