import { clientSplitApi } from '../clientSplitApi'
import {ApiModelsResponse} from "../../common/types";

export const apiWithInvoices = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getInvoices: build.query<ApiModelsResponse, Record<string, any>>({
      query(args) {
        return {
          url: `/invoices`,
          params: {
            context: JSON.stringify(args)
          }
        }
      },
      providesTags: ['Invoice']
    }),
  }),
  overrideExisting: false,
});


