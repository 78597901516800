import { clientSplitApi } from '../clientSplitApi'
import {ApiModelResponse, ApiModelsResponse} from "../../common/types";

export const apiWithPayments = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getPayments: build.query<ApiModelsResponse, Record<string, any>>({
      query(args) {
        return {
          url: `/payments`,
          params: {
            context: JSON.stringify(args)
          }
        }
      },
      providesTags: ['Payment']
    }),
    checkPaymentStatus: build.mutation<ApiModelResponse, Record<string, any>>({
      query(data) {
        return {
          url: `/${data?.provider}/express-checkout-success`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Payment', 'Balance', 'Transaction', 'PaymentMethod']
    }),
  }),
  overrideExisting: false,
});


