import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

export const BoxRow = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center'
});

export const BoxLabel = styled(Box)({
  width: '170px'
});