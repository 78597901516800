import {Layout} from "components/clients/layouts/Layout";
import React from "react";
import Dashboard from "components/clients/billing/dashboard/Dashboard";
import TopUp from "components/clients/billing/balances/TopUp";
import PageContainer from "components/clients/ui/page/PageContainer";
import PageTitle from "components/clients/ui/page/PageTitle";
import Card from "components/clients/ui/card/Card";

const Page = (props: any) => {
  return <Layout>
    <PageContainer>
      <PageTitle>Dashboard</PageTitle>
      <TopUp {...props} />
      <Dashboard {...props} />
    </PageContainer>
  </Layout>
}

export default Page