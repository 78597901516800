import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {CircularProgress} from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Button from "@mui/material/Button";
import {useCallback} from "react";
import {useRouter} from "next/router";

const PaymentCheckoutResponse = ({offlinePaymentData, onClose}: any) => {
  const router = useRouter();
  const paymentStatus = offlinePaymentData?.status

  const onBack = useCallback(() => {
    router.push(`/client/billing/dashboard#modal=topup-payment&currency=${offlinePaymentData?.data?.currency}`)
  }, [offlinePaymentData])

  return <Box>
    <Box mt={4} mb={4}>
      {!paymentStatus && <Stack direction="column" alignItems="center">
        <CircularProgress/>
        <Typography variant="body2" mt={2}>
          Please wait. Completing the transaction.
        </Typography>
      </Stack>}
      {paymentStatus === 'paid' && <Typography>
        {offlinePaymentData.message}
      </Typography>}
      {paymentStatus === 'unpaid' && <Stack direction="column" alignItems="center">
        <DangerousIcon  color='error' fontSize="large" />
        <Typography color='error' mt={1}>{offlinePaymentData.message}</Typography>
      </Stack>}
    </Box>
    <Stack direction="row" justifyContent="flex-end" spacing={3}>
      <Button onClick={onClose} variant="outlined">Close</Button>
      <Button onClick={onBack} variant='contained'>Back</Button>
    </Stack>
  </Box>
}

export default PaymentCheckoutResponse