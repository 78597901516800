import {FCC} from "react";
import Box, {BoxProps} from "@mui/material/Box";

interface CardTitleProps {

}

const CardTitle: FCC<CardTitleProps & BoxProps> = ({children, ...boxProps}) => {
  return <Box
    sx={{
      borderBottom: '1px solid #909DAF',
      textAlign: 'right',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '100%',
      letterSpacing: '0.02em',
      paddingBottom: '4px',
      color: '#909DAF',
      textTransform: 'capitalize',
    }}
    {...boxProps}
  >
    {children}
  </Box>
}

export default CardTitle
