import React, {ComponentType, FCC, ReactNode, useCallback, useMemo, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import {useBooleanStateWithHashSyncing} from "components/shared/helpers/url";

type IUseFormInModal<T> = [FCC<T>, {open: boolean, handleOpen(): void, handleClose(): void}]

export const useFormInModal = <T,>(WrappedComponent: ComponentType<T>, options: {[key: string]: string} = {}): IUseFormInModal<T> => {
  const [open, setOpen] = useBooleanStateWithHashSyncing(false, {name: 'modal', value: options?.name});

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    setOpen(false)
  }, []);

  const form = useMemo(() => {
    return ({...props}) => {
      return <Dialog
        open={open}
        onClose={handleClose}
      >
        {!!props.title && <DialogTitle>{props.title}</DialogTitle>}
        <DialogContent>
          <Box sx={{width: '480px'}}>
            {/*@ts-ignore*/}
            <WrappedComponent onClose={handleClose} {...props} />
          </Box>
        </DialogContent>

      </Dialog>
    }
  }, [open, handleClose]);

  return [form, {open, handleOpen, handleClose}]
}