import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Checkbox, CircularProgress, FormControlLabel, Paper, Select} from "@mui/material";
import {FCC, useCallback, useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import {BoxLabel, BoxRow} from "./Boxes";
import PaymentMethods from "./PaymentMethods";
import AmountSelector from "./AmountSelector";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {useLocationHashParameters} from "components/shared/helpers/url";
import * as React from "react";
import {Balance, PaymentMethod} from "services/client/billing/types";

const getMethodsByCurrency = (paymentMethods: { [key: string]: any }[], balance: { [key: string]: any }) => {
  return paymentMethods
    .filter(pm => pm.currency.code === balance.currency.code)
}

interface ITopUpPaymentForm {
  loading?: boolean
  balances: Balance[]
  paymentMethods: PaymentMethod[]

  onClose?(): void,

  onSubmit(data: any): void
}

const TopUpPaymentForm: FCC<ITopUpPaymentForm> = ({
                                                   loading = false,
                                                   balances = [],
                                                   paymentMethods = [],
                                                   onClose = () => {
                                                   },
                                                   onSubmit = (data) => {
                                                   }
                                                 }) => {
  const hashParameters = useLocationHashParameters();
  const [balance, setBalance] = useState<Balance | undefined>(undefined);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>(undefined);
  const [amountType, setAmountType] = useState<string | undefined>(undefined);
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [errors, setErrors] = useState<Record<string, any> | undefined>(undefined);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const defaultCurrency = balances.filter(b => b.currency.code === hashParameters.currency)[0] ?? balances[0];

    setBalance(defaultCurrency);

    handleChangeCurrency({
      target: {
        value: defaultCurrency
      }
    })
  }, [balances])

  useEffect(() => {
    setErrors(undefined)
  }, [amountType])

  useEffect(() => {
    if (isSubmitted) {
      validate({
        balance,
        paymentMethod,
        amount,
        amountType,
      })
    }
  }, [isSubmitted, balance, paymentMethod, amount, amountType])

  const handleChangeCurrency = useCallback((e) => {
    const newBalance = e.target.value;
    console.log('newBalance', newBalance, balances)

    setBalance(newBalance)

    const methods = getMethodsByCurrency(paymentMethods, newBalance);

    handleChangePaymentMethod(methods[0] ?? null)

    if (newBalance.amount < 0) {
      setAmountType('statement')
      setAmount(newBalance.options.statement_amount)
    } else {
      setAmountType('recommended')
      setAmount(newBalance.options.recommended_amount)
    }
  }, [balance])

  const handleChangePaymentMethod = useCallback((value) => {
    if (value?.options?.enable_auto_payments) {
      setAccepted(false)
    } else {
      setAccepted(true)
    }

    setPaymentMethod(value)
  }, [balance])

  const validate = useCallback(({amount, paymentMethod, balance, amountType}) => {
    setErrors(undefined)

    let errors: Record<string, any> = {};
    if (!amount) {
      errors['amount'] = 'Amount is required';
    } else if (!!amount && amountType === 'custom') {
      if (amount < balance.options.min_payment_amount) {
        errors['amount'] = `Min value is ${balance.options.min_payment_amount}`;
      }
    }

    if (!paymentMethod) {
      errors['paymentMethod'] = 'Payment method is required';
    }

    if (!balance) {
      errors['currency'] = 'Currency is required';
    }

    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      setErrors(errors)
    }

    return !hasErrors;
  }, [])

  const handleSubmit = useCallback(() => {
    const data = {
      balance,
      paymentMethod,
      amount,
      amountType,
    }

    if (validate(data)) {
      onSubmit(data)
    }

    setIsSubmitted(true)
  }, [balance, paymentMethod, amount, amountType])

  if (!balance) {
    return null;
  }

  return <Box>
    <BoxRow mb={2}>
      <BoxLabel>
        <Typography variant='subtitle2'>Balance</Typography>
      </BoxLabel>
      <Box>
        <Select
          value={balance}
          onChange={handleChangeCurrency}
          sx={{minWidth: '100px'}}
        >
          {/*@ts-ignore*/}
          {balances.map((balance) => (<MenuItem sx={{width: '150px'}} value={balance}
                                                key={balance.currency.code}>{balance.currency.code}</MenuItem>))}
        </Select>
      </Box>
    </BoxRow>

    {!paymentMethod && <Box>
      <Typography mb={3} mt={2}>We cannot accept payments in this currency. Please consider auto-converting
        payments.</Typography>
      <Box mt={3}>
        <Stack spacing={2} direction='row' justifyContent="flex-end">
          <Button variant='outlined' onClick={onClose}>Cancel</Button>
          {/*<Button variant="contained" onClick={() => {}}>Auto-converting</Button>*/}
        </Stack>
      </Box>
    </Box>}


    {!!paymentMethod && <Box>
      <Box mb={2}>
        <PaymentMethods
          paymentMethod={paymentMethod}
          paymentMethods={getMethodsByCurrency(paymentMethods, balance)}
          onChange={handleChangePaymentMethod}
        />
      </Box>


      <BoxRow mb={2} sx={{alignItems: 'flex-start'}}>
        <BoxLabel>
          <Typography variant='subtitle2'>Amount</Typography>
        </BoxLabel>
        <Box>
          <AmountSelector
            balance={balance}
            type={amountType as string}
            onChange={(type, amount) => {
              setAmountType(type)
              setAmount(amount)
            }}
            error={errors?.amount}
          />
        </Box>
      </BoxRow>

      <Box mt={3}>
        {paymentMethod?.options?.enable_auto_payments && <Paper variant='outlined' component={Box} px={2} py={1} mb={2}>
          <Stack direction='column'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accepted}
                  onChange={() => setAccepted(!accepted)}
                />
              }
              label={`Save this ${paymentMethod?.id === 'paypal' ? 'account' : 'card'} for future transaction.`}
              labelPlacement="end"
            />
            <Typography sx={{marginLeft: '2rem'}}>
              Once you approve the bill, you will be charged with the Statement amount automatically.
              There will be no other withdrawals except the Statement amount.
            </Typography>
            <Typography variant='caption' sx={{color: 'warning.main', marginTop: '0.4rem', marginLeft: '2rem'}}>
              Saving payment data is required
            </Typography>
          </Stack>
        </Paper>}

        {paymentMethod?.options?.enable_auto_convert && <Paper variant='outlined' component={Box} px={2} py={1} mb={2}>
          <Stack direction='column'>
            <Typography>
              * All payments via PayPal are processed in US dollars. To pay in the currency of your balance, you can use
              Stripe.
            </Typography>
          </Stack>
        </Paper>}

        <Stack alignItems='end'>
          <Stack spacing={2} direction='row' justifyContent="flex-end">
            <Button variant='outlined' onClick={onClose}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!accepted || loading}
              startIcon={loading ? <CircularProgress color="inherit" size='1rem'/> : null}
            >
              Pay now
            </Button>
          </Stack>
        </Stack>

      </Box>
    </Box>}

  </Box>
}

export default TopUpPaymentForm
