import { clientSplitApi } from '../clientSplitApi'
import {ApiModelsResponse} from "../../common/types";

export const apiWithTransactions = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query<ApiModelsResponse, Record<string, any>>({
      query(args) {
        return {
          url: `/transactions`,
          params: {
            context: JSON.stringify(args)
          }
        }
      },
      providesTags: ['Transaction']
    }),
  }),
  overrideExisting: false,
});


