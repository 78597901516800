import { clientSplitApi } from '../clientSplitApi'
import {ApiModelsResponse} from "../../common/types";

export const apiWithBalances = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getBalances: build.query<ApiModelsResponse, void>({
      query() {
        return {
          url: `/balances`,
        }
      },
      providesTags: ['Balance']
    }),
    getBalancesForTopUp: build.query<ApiModelsResponse, void>({
      query() {
        return {
          url: `/balances?options=true`,
        }
      },
      providesTags: ['Balance']
    }),
  }),
  overrideExisting: false,
});


