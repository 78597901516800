import Typography from "@mui/material/Typography";
import Image from 'next/legacy/image'
import AmexIcon from '/public/client/assets/images/components/payment-methods/amex.png'
import MastercardIcon from '/public/client/assets/images/components/payment-methods/mastercard.png'
import PaypalIcon from '/public/client/assets/images/components/payment-methods/paypal.png'
import StripeIcon from '/public/client/assets/images/components/payment-methods/stripe.png'
import VisaIcon from '/public/client/assets/images/components/payment-methods/visa.png'
import React, {FCC} from "react";
import Box from "@mui/material/Box";

const brandsWithImages: {[key: string]: any} = {
  amex: AmexIcon,
  mastercard: MastercardIcon,
  paypal: PaypalIcon,
  stripe: StripeIcon,
  visa: VisaIcon
};

const OutlineContainer: FCC = ({children}) => (<Box
  sx={{
    display: 'inline-flex',
    border: '1px solid #9B9AA2',
    borderRadius: '4px',
    marginRight: '1rem',
    minWidth: '40px',
  }}
>{children}</Box>);

const PaymentMethodImage = ({name, variant}: {name: string, variant: string}) => {
  let Container: FCC = React.Fragment;
  if (variant === 'outline') {
    Container = OutlineContainer
  }

  if (!brandsWithImages[name]) {
    return <Typography></Typography>
  }

  return <Container>
    <Image
      src={brandsWithImages[name]}
      alt={name}
      width={40}
      height={27}
      // blurDataURL="data:..." automatically provided
      // placeholder="blur" // Optional blur-up while loading
    />
  </Container>
}

export default PaymentMethodImage